@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
}

body {
    overflow-x: hidden;
    margin-bottom: -30px;
}

p, input, button, a, span {
    font-family: 'Montserrat', sans-serif;
}

/* autocomplete settings */

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(30, 30, 30);
  -webkit-box-shadow: 0 0 0px 1000px lightblue inset;
  transition: background-color 5000s ease-in-out 0s;
}

.btn {
    border: none;
    text-align: start;
    background-color: transparent;
    color: rgb(37, 37, 37);
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

/* #region Header.js */

.header {
    position: relative;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0;
    background-color: rgb(0, 23, 59);
}

.header > div {
    color: white;
    width: 250px;
    text-align: center;
}

.header > div > a:hover{
    color: rgb(156, 156, 156);
    transition: 300ms;
}

.header > div > a > i {
    margin-right: 15px;
}

.header > div > a {
    text-decoration: none;
    font-weight: 600;
    color: white;
    transition: 300ms;
}

/* #endregion */

/* #region Navbar */

.navbar {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 10px 10px -10px grey;
}

.hideNavbar {
    display: none;
}

.navbarImg {
    margin-left: 50px;
    height: 100px;
}

.navbarRight {
    display: flex;
    align-items: center;
    margin-right: 70px;
}

.navlink {
    padding: 55px 20px;
    transition: 200ms;
    font-size: 18px;
    font-weight: 650;
    border-bottom: 5px white solid;
    cursor: pointer;
}

.navlink:hover {
    border-bottom: 5px #E57B15 solid;
    transition: 200ms;
    color: #E57B15;
}

.navbarBtn {
    border-radius: 500px;
    padding: 13px 35px;
    margin-right: 10px;
    background-color: rgb(0, 23, 59);
    color: whitesmoke;
    font-weight: 700;
    font-size: medium;
    transition: 200ms;
    border: none;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.navbarBtn:hover {
    transition: 200ms;
    background-color: #E57B15;
    color: white;
    border: none;
}

.hamburger {
    align-items: center;
    margin-right: 15px;
    display: none;
    padding: 45px 20px;
}

.hamburger>i {
    font-size: 28px;
    cursor: pointer;
    transition: 500ms;
    color: rgb(0, 23, 59);
}

.hamburger>i:hover {
    color: rgb(68, 68, 68);
    transition: 500ms;
}

/* #endregion */

/* #region Sidebar */

.sidebar {
    position: fixed;
    width: 400px;
    box-shadow: 0 0 10px 3px rgb(35, 35, 35);
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    bottom: 0;
    left: -700px;
    padding: 50px;
    background-color: rgb(229, 229, 229);
    z-index: 2;
    transition: 200ms;
}

.showSidebar {
    left: 0;
    transition: 200ms;
}

.sidebarImg {
    height: 80px;
}

.sidebarCover {
    position: fixed;
    z-index: 1;
    background-color: black;
    opacity: 68%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.sidebarX {
    font-size: 30px;
    color: rgb(15, 29, 53);
    cursor: pointer;
    transition: 500ms;
}

.sidebarX:hover {
    color: rgb(68, 68, 68);
    transition: 500ms;
}

.sidebarTop {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    width: 100%;
}

.sidebarBottom {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.sidebarNavlink {
    color: rgb(62, 62, 62);
    font-weight: 700;
    font-size: 18px;
    padding: 30px 0;
    cursor: pointer;
    border-bottom: 2px rgb(190, 190, 190) solid;
    transition: 500ms;
}

.sidebarNavlinkLast {
    border-bottom: none;
}

.sidebarNavlink:hover {
    transition: 500ms;
    color: #E57B15;
}

/* #endregion */

/* #region Welcome */

.welcome {
    position: relative;
    background-attachment: fixed;
    background-image: url("./images/home-bg.jpg");
    width: 100%;
    min-height: 600px;
    height: 80vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Make text readable */
    z-index: 0;
}

.welcome::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1;
}

.welcome > * {
    position: relative;
    z-index: 2; /* Ensure text appears above overlay */
}


.welcomeTitle {
    font-size: 60px;
    color: whitesmoke;
    text-align: center;
    opacity: 0;
    margin-left: -200px;
}

.welcomeTitleShow {
    opacity: 100%;
    transition: 1.5s;
    margin-left: 0;
}

.welcomeDescription {
    color: lightgrey;
    font-weight: 500;
    font-size: 17px;
}

.welcomeBtn {
    border-radius: 500px;
    padding: 13px 60px;
    background-color: rgb(0, 23, 59);
    color: whitesmoke;
    font-weight: 700;
    font-size: large;
    transition: 200ms;
    border: none;
    margin-top: 15px;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.welcomeBtn:hover {
    transition: 200ms;
    background-color: #E57B15;
    color: rgb(0, 23, 59);
}

/* #endregion */

/* #region Home */

.home {
    background-color: whitesmoke;
}

.experienceTitle {
    font-size: 25px;
    display: flex;
    justify-content: space-around;
}

.experienceTitleNum {
    font-size: 275px;
    margin-top: -90px;
}

.experienceSubtitle {
    text-align: end;
}

.descSection {
    padding: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.descLeft {
    width: 60%;
}

.descTitle {
    color:rgb(30, 30, 30);
    padding: 20px 0;
    border-bottom: 2px lightgrey solid;
    font-size: 30px;
    font-weight: 650;
    margin: 0;
}

.homeFacts {
    display: flex;
}

.homeFact {
    width: fit-content;
    margin-top: 50px;
    margin-right: 150px;
}

.homeFact > p {
    color: rgb(89, 89, 89);
    font-size: 25px;
    margin: 0;
}

.homeFact > div {
    display: flex;
    align-items: center;
}

.homeFact > div > i {
    font-size: 50px;
    color:#E57B15;
}

.homeFact > div > h1 {
    font-size: 70px;
    margin: 0;
    margin-left: 20px;
    margin-bottom: 5px;
    font-weight: 500;
}

.desc {
    color: rgb(30, 30, 30);
    font-size: 17px;
}

.descImg {
    height: 300px;
    z-index: 1;
    position: absolute;
    right: 0;
    margin-top: -50px;
    margin-left: 20px;
}

.imgBar {
    position: absolute;
    background-color: #E57B15;
    height: 500px;
    width: 100px;
    right: 0;
}


/* #endregion */

/* #region ServicesPrev */

.dividerServices {
    padding: 30px;
    background-color: rgb(0, 23, 59);
    display: flex;
    justify-content: space-evenly;
}

.dividerServices > h1 {
    color: whitesmoke;
}

.dividerServices > h1 > span {
    color: #E57B15;
}

.dividerServices > div {
    display: flex;
    align-items: center;
}

.dividerServicesBtn {
    background-color: whitesmoke;
    color: rgb(30, 30, 30);
    border: none;
    padding: 20px 40px;
    border-radius: 1000px;
    font-weight: 700;
    font-size: 20px;
    transition: 300ms;
    cursor: pointer;
    letter-spacing: 0.5px;
    white-space: nowrap;
}

.dividerServicesBtn:hover {
    background-color: #E57B15;
    color: whitesmoke;
    transition: 300ms;
}

.dividerServices > h1 > br {
    display: none;
}

.servicesPrevSection {
    padding: 30px;
}

.servicesPrevTitle {
    text-align: center;
    font-size: 40px;
}

.servicesPrev {
    display: flex;
}

.servicePrev {
    padding: 0 30px;
    border-right: 2px lightgrey solid;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33.33%
}

.servicePrevLast {
    border: none;
}

.servicePrev > h3 {
    font-size: 1.3em;
}

.servicePrev > i {
    font-size: 30px;
    padding: 20px 0;
    color: #E57B15;
}

.servicePrev > p, .servicePrevLast > p {
    color: rgb(30, 30, 30);
}

.servicePrevSeeMoreContainer {
    display: flex;
    justify-content: center;
}

.servicePrevSeeMore {
    white-space: nowrap;
    color: #E57B15;
    font-size: large;
    font-weight: 600;
    cursor: pointer;
    transition: 300ms;
    text-align: center;
    margin-top: 30px;
    width: fit-content;
}

.servicePrevSeeMore:hover {
    color: rgb(30, 30, 30);
    transition: 300ms;
}

.servicePrevSeeMore > i {
    margin-left: 10px;
}

/* #endregion */

/* #region ContactForm */

.contactFormSection {
    background-color: rgb(0, 23, 59);
    margin-bottom: -28px;
}

input {
    outline: none;
    border: none;
    background-color: transparent;
}

.contactForm {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactFormTop, .contactFormBottom {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0;
}

.contactFormInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactFormInput > input {
    padding: 15px 20px;
    width: 70%;
    color: rgb(30, 30, 30);
    background-color: whitesmoke;
    font-size: 18px;
    font-weight: 600;
}


.contactFormTitle {
    text-align: center;
    padding-top: 50px;
    color: whitesmoke;
    font-size: 40px;
}

.contactFormInputTop {
    display: flex;
    justify-content: space-between;
    width: 70%;
    align-items: center;
}

.contactFormRequired {
    color: red;
    text-align: center;
}

.contactFormStar {
    color: red;
    font-size: 25px;
    margin: 0;
}

.contactFormLabel {
    color: lightgrey;
    margin-bottom: 10px;
}

.contactFormBtnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 30px 0;
    padding: 0 7.5%;
}

.contactFormBtn {
    background-color: #E57B15;
    border: none;
    border-radius: 0;
    color: lightgrey;
    padding: 18px 45px;
    font-size: 18px;
    cursor:not-allowed;
    font-weight: 700;
    margin-bottom: 30px;
    letter-spacing: 0.5px;
    opacity: 80%;
    border: 3px #E57B15 solid;
}

.contactFormBtnShow {
    opacity: 100%;
    color: whitesmoke;
    cursor: pointer;
    transition: 300ms;
}

.contactFormBtnShow:hover {
    background-color: transparent;
    color: #E57B15;
    transition: 300ms;
}

.contactFormTextareaTop {
    display: flex;
    justify-content: space-between;
    width: 85%;
    align-items: center;
    margin-top: 20px;
}

.contactFormTextarea {
    resize: none;
    width: 85%;
    height: 250px;
    padding: 20px;
    font-size: 19px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border: none;
    outline: none;
}



/* #endregion */

/* #region Footer */

.footerLight {
    padding: 30px;
    background-color: rgb(228, 228, 228);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.footerLight > img {
    margin: 0;
}

.footerLight > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
}

.footerLight > div > p {
    font-weight: 600;
    color: rgb(40, 40, 40);
}

.footerLight > div > div {
    display: flex;
    justify-content: flex-end;
}

.footerLight > div > div > a {
    margin-left: 10px;
    color: rgb(40, 40, 40);
    font-size: 30px;
    cursor: pointer;
    transition: 200ms;
}

.footerLight > div > div > a:hover {
    color: grey;
    transition: 200ms;
}

.footerDark {
    background-color: rgb(0, 23, 59);
}

.footerDark > div > p {
    color: lightgrey;
}

.footerDark > div > div > a {
    color: lightgrey;
}

.footerDark > div > div > a:hover {
    color: grey;
}

/* #endregion */

/* #region Submitted */

.submitted {
    background-image: url("./images/desc-dark.jpg");
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-position: center;
    background-size: cover;
}

.submitted > h1 {
    color: whitesmoke;
    font-size: 35px;
    margin-bottom: 50px;
    text-align: center;
    margin: 0 50px;
}

.submitted > button {
    padding: 22px 50px;
    border-radius: 1000px;
    font-size: 25px;
    font-weight: 600;
    background-color: rgb(0, 23, 59);
    color: whitesmoke;
    border: none;
    transition: 300ms;
    cursor: pointer;
    margin-top: 30px;
}

.submitted > button:hover {
    background-color: #E57B15;
    color: black;
    transition: 300ms;
}

/* #endregion */

/* #region Contact */

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactTitle {
    text-align: center;
    margin: 50px 20px 20px 20px;
    font-size: 40px;
    color: rgb(30, 30, 30)
}

.contactDesc {
    text-align: center;
    color: grey;
    padding: 0 20%;
}

.contactBox {
    display: flex;
}

.contactBoxPhone {
    padding: 30px;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: whitesmoke;
}

.contactBoxPhoneRow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
    border-bottom: 1px solid rgb(228, 228, 228);
    width: 100%;
}

.contactBoxPhoneRow:nth-of-type(3) {
    margin-bottom: 0;
    border-bottom: none;
}

.contactBoxPhoneRow > div > p {
    margin: 5px 0 0 20px;
    font-size: 16px;
    font-weight: 500;
    color: grey;
    transition: 200ms;
    white-space: nowrap;
}

.contactBoxPhoneRow > div > p:nth-child(1) {
    font-size: 20px;
    font-weight: 600;
    color: rgb(30, 30, 30);
}

.contactBoxPhoneRow > i {
    margin: 0 10px;
    font-size: 20px;
    color: #E57B15;
}

.contactLink:hover {
    color: rgb(69, 69, 69);
    cursor: pointer;
    transition: 200ms;
}

.contactContainer {
    border: 1px solid lightgrey;
    border-radius: 10px;
    margin: 30px;
    display: flex;
    width: 80%;
}

    /* contact form section */

.contactPageForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0 30px 0;
}

.contactFormContainer {
    width: 100%;
}

.contactTop, .contactBottom {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0;
}

.contactTop {
    margin-top: 0;
}

.contactInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0 13px 0 0;
}

.contactInputTop {
    width: 85%;
}

.contactInput:nth-child(even) {
    align-items: flex-start;
    margin: 0 0 0 13px;
}

.contactInput > input {
    padding: 15px 20px;
    width: 85%;
    color: rgb(30, 30, 30);
    background-color: rgb(236, 236, 236);
    font-size: 18px;
    font-weight: 600;
}

.contactTextarea {
    resize: none;
    width: 85%;
    background-color: rgb(236, 236, 236);
    height: 200px;
    padding: 20px;
    font-size: 19px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border: none;
    outline: none;
}

.contactLabel {
    font-size: 17px;
    font-weight: 500;
}

/* #endregion */

/* #region Photos */

.photos {
}

.photosTitleContainer {
    background-image: url('./images/desc.jpg');
    width: 100%;
    padding: 20px;
    background-position: right;
    color: whitesmoke;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.photosTitle {
    text-align: center;
    font-size: 40px;
}

.photosContainer {
    display: grid;
    grid-auto-rows: 290px;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-auto-flow: dense;
    margin-bottom: 50px;
}

.photo {
    border-radius: 20px;
    width: 300px;
    height: 300px;
    padding: 10px;
    object-fit: cover;
}

/* #endregion */

/* #region About Us */

.aboutTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./images/desc.jpg');
    background-attachment: fixed;
    background-position: right;
    padding: 3%;
}

.aboutDescriptionContainer {
    padding: 5% 10%;
    display: flex;
}

.aboutDescription {
    color: rgb(30, 30, 30);
    font-size: 20px;
    line-height: 150%;
    width: 70%;
}

.aboutDescriptionRight {
    border-left: 2px rgb(189, 189, 189) solid;
    margin-left: 20px;
    padding: 0px 40px;
    display: flex;
    align-items: center;
}

.aboutDescriptionLeft {
    border: none;
    margin: 0;
    border-right: 2px rgb(189, 189, 189) solid;
    margin-right: 70px;
}

.aboutDescriptionTitle {
    font-size: 40px;
}

.aboutDescriptionTitle > span {
    color:#E57B15;
}

.aboutServicesContainer {
    padding: 0;
    background: linear-gradient(130deg, rgb(255, 255, 255), rgb(222, 222, 222), rgb(255, 255, 255));
    background-size: 200% 200%;
    display: flex;
    justify-content: space-between;

    -webkit-animation: Animation 5s ease infinite;
    -moz-animation: Animation 5s ease infinite;
    animation: Animation 5s ease infinite;
}

@-webkit-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes Animation { 
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}

.aboutServicesLeft {
    padding: 100px;
    padding-right: 25px;
}

.aboutServices {
    display: flex;
    margin-top: 70px;
}

.aboutService {
    margin: 0 20px;
    background-color: white;
    height: 200px;
    width: 200px;   
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 1500px;
    border: 4px white solid;
    transition: 200ms;
}

.aboutService:hover {
    cursor: pointer;
}

.aboutService > i {
    font-size: 50px;
    color: rgb(179, 179, 179);
    margin-top: 10px;
}

.aboutServiceActive {
    background-color: #E57B15;
    transition: 200ms;
    cursor: pointer;
    border-color: #E57B15;
}

.aboutServiceActive > i {
    color: white;
    transition: 200ms;
}

.aboutServiceActive > h4 {
    color: white;
}

.aboutServiceOffer {
    margin: 0;
    position: relative;
    bottom: 0;
    padding: 50px;
    background-image: url('C:/Users/ibrah/documents/dawcon-website/src/images/commercial-about.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white; /* Optional: ensure text is visible */
    z-index: 1;
}

.aboutServiceOffer::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
    z-index: 0;
}

.aboutServiceOffer > * {
    position: relative;
    z-index: 1;
}


.aboutServiceOfferR {
    background-image: url('C:/Users/ibrah/documents/dawcon-website/src/images/residential-about.jpg');
}

.aboutServiceOfferD {
    background-image: url('C:/Users/ibrah/documents/dawcon-website/src/images/designbuild-about.jpg')
}

.aboutServiceOffer > h1 {
    text-align: center;
    color: orange;

}

.aboutServiceOffer > p {
    text-align: center;
    padding: 20px;
    color: white;
    font-size: 20px;
    margin-bottom: 0;
    line-height: 175%;
    font-weight: 500;
}

.aboutVisionTitle {
    white-space: nowrap;
}

.aboutServiceDots {
    display: flex;
    width: 100%;
    position: absolute;
    justify-content: center;
    bottom: 20px;
    left: 0;
}

.aboutServiceDot {
    width: 10px;
    height: 10px;
    background-color: white;
    border-radius: 100px;
    margin: 0 10px;
    opacity: 30%;
    cursor: pointer;
}

.aboutServiceDotActive {
    opacity: 100%;
}

/* #endregion */

/* #region Responsiveness */

@media (min-width: 2000px) {
    .welcome {
        height: 800px;
    }
}

@media(min-width: 1000px){
    .header > div {
        margin: 0 100px;
    }

    .dividerServices > h1 {
        font-size: 35px;
    }
}

@media(max-width: 1400px) {
    .aboutServiceOffer > p {
        font-size: 15px;
    }

    .aboutService {
        width: 175px;
        height: 175px;
    }

    .aboutService > i {
        font-size: 35px;
    }
}

@media(max-width: 1200px){
    .aboutService {
        width: 150px;
        height: 150px;
    }

    .aboutServiceTitle {
        font-size: 15px;
    }

    .aboutServicesLeft {
        padding-left: 50px;
    }

    .aboutService > i {
        font-size: 30px;
    }

    .imgBar {
        width: 70px;
    }

    .descImg {
        height: 250px;
    }

    .navlink{
        font-size: 16px;
    }
}

@media(max-width: 1075px){
    .navbarImg {
        height: 80px;
        margin-left: 20px;
    }

    .navbarRight {
        margin-right: 20px;
    }
}

@media(max-width: 1000px){
    .aboutDescriptionTitle {

    }

    .aboutDescriptionContainer {
        flex-direction: column-reverse;
        align-items: center;
        padding: 20px 70px;
    }

    .aboutVisionContainer {
        flex-direction: column;
    }

    .aboutDescriptionLeft {
        border-right: none;
    }

    .aboutDescriptionRight {
        border-left: none;
        border-bottom: 2px solid lightgrey;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .aboutDescription {
        text-align: center;
        width: 100%;
        font-size: 17px;
    }

    .aboutServicesContainer {
        flex-direction: column;
    }
    .aboutServicesLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 50px 20px
    }

    .aboutServiceOffer {
        height: 560px;
    }

    .aboutServiceOffer > h1 {
        font-size: 25px;
    }

    .aboutServices {
        margin-top: 10px;
    }

    .descImg, .imgBar {
        display: none;
    }

    .descLeft {
        width: 100%;
    }

    .contactContainer {
        flex-direction: column;
    }

    .contactBox {
        justify-content: center;
    }

    .contactBoxPhone {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .navlink {
        display: none;
    }

    .hamburger {
        display: flex;
    }

    .welcomeTitle {
        font-size: 45px;
        padding: 0 20px;
    }

    .welcomeDescription {
        font-size: medium;
        text-align: center;
        padding: 0 20px;
    }

    .welcomeBtn {
        padding: 11px 50px;
        font-size: medium;
    }

    .welcomeContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .homeFact > div > i {
        font-size: 40px;
    }

    .homeFact > div > h1 {
        font-size: 60px;
    }

    .homeFact > p {
        font-size: 20px;
    }

    .descSection {
        padding: 50px;
    }

    .homeFacts {
        justify-content: space-around;
        margin-top: 40px;
    }

    .homeFact {
        margin: 0;
    }

    .dividerServices > h1 {
        font-size: 1.5em;
    }

    .dividerServicesBtn {
        font-size: 14px;
        padding: 15px 30px;
        margin-left: 10px;
    }

    .servicePrev > p {
        font-size: 0.9em;
    }

    .servicesPrev {
        flex-direction: column;
        padding: 0 50px;
        align-items: center;
    }

    .servicesPrevTitle {
        margin: 20px 0 40px 0;
    }
    .servicePrev {
        border: none;
        border-bottom: 2px lightgrey solid;
        padding: 20px 50px;
        width: 85%;
    }

    .servicePrevLast {
        border: none;
    }
    
    .servicePrev > i {
        padding: 0;
    }

    .servicePrevSeeMore {
        margin: 0;
    }

    .servicePrev > h3 {
        margin-bottom: 0;
    }

    .contactDesc {
        padding: 0 10%;
    }
}

@media(max-width: 750px) {
    .footerLight {
        flex-direction: column;
        align-items: center;
    }

    .footerLight > img {
        height: 80px;
    }

    .footerLight > div {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 30px;
    }
}

@media(max-width: 700px) {
    .aboutService {
        display: none;
    }

    .aboutDescriptionTitle {
        text-align: center;
        margin-left: -16px;
        font-size: 30px;
    }

    .aboutService {
        height: 80px;
        width: 80px;
        padding-bottom: 10px;
    }

    .dividerServices > h1 > br {
        display: block;
    }

    .contactTop, .contactBottom {
        display: flex;
        flex-direction: column;
    }

    .contactInput {
        margin-left: 0;
        margin-right: 0;
        align-items: center;
    }

    .contactInput:nth-child(even) {
        align-items: center;
        margin: 0;
    }
}

@media(max-width: 600px) {
    .photos {
        width: 100%;
    }
    .photosContainer {
        grid-auto-rows: 40vw;
        grid-template-columns: repeat(auto-fit, minmax(40vw, 1fr));
    }
    .photo {
        width: 40vw;
        height: 40vw;
        display: flex;
        justify-self: center;
        align-self: center;
    }

    .navbarImg {
        height: 80px;
        margin-left: 20px;
    }
    .submitted > h1 {
        font-size: 30px;
        font-weight: 700;
    }

    .submitted > button {
        padding: 15px 33px;
        font-size: 20px;
        margin-top: 30px;
    }
}

@media(max-width: 550px){
    .welcome {
        background-attachment: scroll;
    }
    .aboutTitleContainer {
        background-attachment: scroll;
    }
    .servicePrev {
        padding: 20px 0;
    }
    .aboutServicesLeft {
        padding: 10px 0;
    }
    .header > div {
        margin-right: 50px;
    }

    .showSidebar {
        right: 0;
        width: auto;
        padding: 30px;
    }

    .sidebarBottom {
        align-items: center;
        justify-content: flex-start;
        margin-top: 50px;
    }

    .sidebarBottom > a {
        width: 100%;
        display: flex;
        justify-content: center;
        text-decoration: none;
        height: 100px;
    }

    .sidebarNavlink {
        width: 50%;
        text-align: center;
        font-size: 17px;
    }

    .descSection {
        padding: 20px;
    }

    .homeFacts {
        flex-direction: column;
        height: 300px;
        align-items: center;
    }

    .desc {
        font-size: 15px;
        text-align: center;
    }

    .descTitle {
        font-size: 25px;
        text-align: center;
    }

    .servicesPrev {
        padding: 0;
    }

    .contactFormTop, .contactFormBottom {
        flex-direction: column;
    }

    .contactFormInput > input{
        width: 85%;
    }

    .contactFormInputTop {
        width: 85%;
    }

    .contactFormInput > input {
        margin-bottom: 15px;
    }
}

@media(max-width: 450px){
    .header > div > a {
        font-size: 14px;
    }

    .header > div {
        margin: 0;
    }

    .welcomeTitle {
        font-size: 35px;
    }

    .welcomeDescription {
        font-size: small;
    }
    
    .dividerServices {
        padding: 15px;
    }
    /* .dividerServices > h1 {
        font-size: 1.25em;
    }

    .dividerServicesBtn {
        padding: 12px 24px;
        font-size: 12px;
    } */

    .contactFormInput > input, .contactFormTextarea{
        width: 85%;
    }

    .contactFormInputTop, .contactFormTextareaTop {
        width: 85%;
    }

    .contactFormBtnContainer {
        padding: 0 7.5%;
    }

    .footerLight > div > p {
        font-size: 13px;
    }

    .footerLight > div {
        margin-top: 5px;
    }

    .contactBoxPhone {
        padding: 10px;
    }

    .photosContainer {
        grid-auto-rows: 80vw;
        grid-template-columns: repeat(auto-fit, minmax(80vw, 1fr));
    }
    .photo {
        width: 95vw;
        height: 80vw;
    }
}

/* #endregion */                                                                                                                                                                                                                                                        